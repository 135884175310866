<template>
  <div class="page">
    <div class="page-header">
      <h3>{{ user.nama }}</h3>
    </div>
    <div class="pekerjaan">
      <div v-if="loading">
        <div class="loading" style="margin-top: 100px">
          <div class="dot-falling"></div>
        </div>
        <p style="text-align: center">Mengambil data</p>
      </div>
      <div v-else>
        <div class="empty" v-if="data.length <= 0">
          <p>Tidak ada data</p>
        </div>
        <ul class="list">
          <li v-for="(item, index) in data" :key="index">
            <router-link :to="'/pekerjaan/' + item.id">
              <div>
                <label>
                  <b> {{ moment(item.tgl_pekerjaan).format("DD MMM YYYY") }}</b>
                </label>
                <div class="checkpoint">
                  <Location />
                  <small> {{ item.checkpoint }} </small>
                </div>
              </div>
              <div>
                <span>{{ item.divisi }}</span>
                <small>{{ item.total_flight }} flight</small>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>

    <div class="modal" :class="{ open: modalOpen }" ref="modal">
      <div class="modal-body" :class="{ open: modalBodyOpen }">
        <p><b>Update Password</b></p>
        <br />
        <p>Silakan update password terlebih dahulu untuk melanjutkan</p>

        <form @submit.prevent="updatePassword()">
          <div class="form-group">
            <input
              type="password"
              placeholder="Password Baru"
              v-model="user.password"
            />
          </div>
          <div class="form-group" style="margin-bottom: 2px">
            <input
              type="password"
              placeholder="Konfirmasi Password Baru"
              v-model="user.konfirmasi_password"
            />
          </div>

          <button type="submit" class="btn btn-primary">Update Password</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "./../api";
import moment from "moment";

import Location from "./../assets/icons/Location.vue";

export default {
  name: "Home",
  components: {
    Location,
  },
  data() {
    return {
      loading: false,
      user: this.$store.state.login.data,
      data: [],
      modalOpen: false,
      modalBodyOpen: false,
    };
  },
  methods: {
    moment,
    load() {
      this.loading = true;
      api
        .get("/v1/kidora/PekerjaanController/get?id_sdm=" + this.user.id)
        .then((r) => {
          this.loading = false;
          this.data = r.data;
        });
    },
    updatePassword() {
      if (!this.user.password) {
        this.$toast("Password harus diisi");
        return;
      }

      if (this.user.password != this.user.konfirmasi_password) {
        this.$toast("Password tidak sama");
        return;
      }

      const user = this.$store.state.login.data;

      api
        .postJson("/v1/kidora/SdmController/updatePassword", {
          id: user.id,
          password: this.user.password,
        })
        .then((r) => {
          this.$toast("Password berhasil diubah");
          this.modalOpen = false;

          setTimeout(() => {
            this.$refs["modal"].remove();
          }, 100);

          const param = {
            password: this.user.password,
            last_password_updated: 1,
          };

          this.$store.commit("login/updatePassword", param);
        });
    },
  },

  mounted() {
    this.user = this.$store.state.login.data;
    setTimeout(() => {
      if (!this.$store.state.login.data.last_password_updated) {
        this.modalOpen = true;
        setTimeout(() => {
          this.modalBodyOpen = true;
        }, 100);
      } else {
        this.$refs["modal"].remove();
      }
    }, 500);
    this.load();
  },
};
</script>

<style lang="scss" scoped="true">
.page {
  .empty {
    text-align: center;
    margin-top: 100px;
  }
  .page-header {
    padding: 40px 20px;
    background: var(--light-blue);

    h3 {
      margin: 0;
    }
  }
  .pekerjaan {
    padding: 20px;
  }
  ul {
    li {
      padding: 5px 0;
      div {
        .checkpoint {
          margin-top: 5px;
          display: flex;
          align-items: center;
          svg {
            height: 16px;
          }
        }
        &:last-child {
          span,
          small {
            display: block;
            text-align: right;
          }
        }
      }
    }
  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  display: flex;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease;
  &.open {
    opacity: 1;
  }

  .modal-body {
    border-radius: 5px;
    margin: 30px;
    margin-top: -250px;
    background: #fff;
    padding: 15px;
    height: 260px;
    width: 300px;
    transition: all 0.3s ease;
    &.open {
      margin-top: 70px;
    }
    p {
      margin: 0;
    }
    button {
      margin-top: 10px;
    }

    form {
      margin-top: 20px;
      input {
        width: calc(100% - 22px);
      }
    }
  }
}
</style>
