<template>
  <div id="app">
    <div class="not-mobile" v-if="!isMobile">
      Silakan gunakan handphone untuk mengakses aplikasi ini
    </div>
    <div v-else>
      <div class="router">
        <router-view></router-view>
      </div>
      <div class="bottom-bar" v-if="$route.path != '/login'">
        <ul>
          <li :class="{ active: path == '/' }">
            <router-link to="/">
              <div class="bottom-icon">
                <pekerjaan />
              </div>
              <p>Pekerjaan</p>
            </router-link>
          </li>
          <li :class="{ active: path == '/kehadiran' }">
            <router-link to="/kehadiran">
              <div class="bottom-icon">
                <kehadiran />
              </div>
              <p>Kehadiran</p>
            </router-link>
          </li>
          <li :class="{ active: path == '/profil' }">
            <router-link to="/profil">
              <div class="bottom-icon">
                <profil />
              </div>
              <p>Profil</p>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "./api";

import Pekerjaan from "./assets/icons/Pekerjaan.vue";
import Kehadiran from "./assets/icons/Kehadiran.vue";
import Profil from "./assets/icons/Profil.vue";

import "three-dots/dist/three-dots.css";

export default {
  name: "app",
  components: {
    Pekerjaan,
    Kehadiran,
    Profil,
  },
  data() {
    return {
      path: "/",
      isMobile: false,
    };
  },
  methods: {
    updateToken(token) {
      const data = {
        id: this.$store.state.login.data.id,
        firebase_token: token,
      };
      api
        .postJson("/v1/kidora/SdmController/updateFcmToken", data)
        .then((r) => {
          console.log(r.valid);
        });
    },
  },
  mounted() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.isMobile = true;
    } else {
      return;
    }

    this.path = this.$route.path;

    const self = this;
    document.addEventListener(
      "deviceready",
      () => {
        cordova.plugins.firebase.messaging
          .requestPermission({ forceShow: true })
          .then(function () {
            cordova.plugins.firebase.messaging
              .getToken()
              .then(function (token) {
                if (token) {
                  self.updateToken(token);
                }
              });

            cordova.plugins.firebase.messaging.onTokenRefresh(function (token) {
              if (token) {
                self.updateToken(token);
              }
            });

            cordova.plugins.firebase.messaging.subscribe(
              "KIDORA_OS_NOTIFICATION"
            );
          });
      },
      false
    );

    if (
      !this.$store.state.login.data.is_login &&
      this.$route.path != "/login"
    ) {
      this.$router.push("/login");
    }
  },
  watch: {
    $route(to) {
      this.path = to.path;
    },
  },
};
</script>

<style lang="scss">
html,
body {
  padding: 0;
  margin: 0;
}
:root {
  --light-blue: #e5f3fb;
  --primary-lighter: #71b6e0;
  --primary: #0e5f90;
  --primary-darker: #043d60;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 500px;
  margin: 0 auto;
  @media screen and (min-width: 600px) {
  }
}
.not-mobile {
  margin-top: 30px;
  background-color: rgb(247, 231, 107);
  border: 1px solid rgb(235, 184, 17);
  padding: 20px;
  text-align: center;
  box-shadow: 0px 5px 5px #ccc;
}

.form-group {
  margin-bottom: 15px;
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  input {
    width: calc(100% - 22px);
    font-size: 14px;
  }
}
.btn {
  background-color: #eee;
  border: 0;
  border-radius: 4px;
  padding: 15px;
  width: 100%;
  text-transform: uppercase;
  font-weight: bold;
  &.btn-primary {
    background-color: var(--primary);
    color: white;
    &:active {
      background-color: var(--primary-darker);
    }
  }
  &:disabled {
    background-color: var(--primary-lighter);
    &:active {
      background-color: var(--primary-lighter);
    }
  }
}
input {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  outline: none;
  &:focus {
    border-color: var(--primary);
  }
}
.router {
  padding-bottom: 64px;
}
.bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid #ccc;
  background-color: white;
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-around;
    li {
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.5;
      &.active {
        opacity: 1;
      }
      a {
        display: block;
        width: 100%;
        color: #333;
        text-decoration: none;
        text-align: center;
        font-size: 14px;
        padding: 15px 5px 5px;
        .bottom-icon svg {
          height: 20px;
        }
        p {
          margin: 0;
        }
      }
    }
  }
}
.loading {
  display: flex;
  justify-content: center;
}
.dot-falling {
  background-color: #0e5f90;
  color: #0e5f90;
  &:before,
  &:after {
    background-color: #0e5f90;
    color: #0e5f90;
  }
}
ul.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  li {
    a {
      color: #333;
      text-decoration: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      border-bottom: 1px solid #ccc;
      label {
        display: block;
      }
      &:active {
        background-color: var(--light-blue);
      }
    }
  }
}
</style>
