<template>
  <div class="page">
    <div class="page-header" v-if="data[0]">
      <h3>{{ moment(data[0].tgl_pekerjaan).format("DD MMM YYYY") }}</h3>
      <p>
        <Briefcase />
        {{ data[0].divisi }}
      </p>
      <p>
        <Location />
        {{ data[0].checkpoint }}
      </p>
    </div>
    <div class="pekerjaan">
      <div v-if="loading">
        <div class="loading" style="margin-top: 100px">
          <div class="dot-falling"></div>
        </div>
        <p style="text-align: center">Mengambil data</p>
      </div>
      <div v-else>
        <ul>
          <li v-for="(item, index) in data" :key="index">
            <div>
              <b>{{ item.maskapai }} ({{ item.flight_number }})</b>
              <small>
                {{
                  moment(
                    item.departure_date + " " + item.departure_time
                  ).format("DD-MM-YYYY HH:mm")
                }}
              </small>
            </div>
            <div>
              {{ item.destination }}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "../api";
import moment from "moment";

import Briefcase from "./../assets/icons/Briefcase.vue";
import Location from "./../assets/icons/Location.vue";

export default {
  name: "Home",
  components: {
    Briefcase,
    Location,
  },
  data() {
    return {
      loading: false,
      user: this.$store.state.login.data,
      data: [],
    };
  },
  methods: {
    moment,
    load() {
      this.loading = true;
      api
        .get(
          "/v1/kidora/PekerjaanController/detail?id_pekerjaan=" +
            this.$route.params.id
        )
        .then((r) => {
          this.loading = false;
          this.data = r.data;
        });
    },
  },

  mounted() {
    this.load();
  },
};
</script>

<style lang="scss" scoped="true">
.page {
  .empty {
    text-align: center;
    margin-top: 100px;
  }
  .page-header {
    padding: 30px 20px;
    background: var(--light-blue);

    h3,
    p {
      margin: 0;
    }

    p {
      display: flex;
      align-items: center;
      margin: 5px 0;

      svg {
        height: 15px;
        margin-right: 10px;
      }
    }
  }
  .pekerjaan {
    padding: 20px;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 0;
        border-bottom: 1px solid #ccc;
        small {
          display: block;
          margin: 0;
        }
      }
    }
  }
}
</style>
