<template>
  <div class="page">
    <div class="circle top"></div>
    <div class="circle bottom"></div>

    <div class="login">
      <h3>Kidora OS</h3>
      <p>Silakan login untuk melanjutkan</p>

      <form @submit.prevent="login()">
        <div class="form-group">
          <label>Username</label>
          <input type="text" v-model="data.username" />
        </div>
        <div class="form-group">
          <label>Password</label>
          <input type="password" v-model="data.password" />
        </div>
        <button type="submit" class="btn btn-primary" :disabled="loading">
          Login
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import * as api from "./../api";

export default {
  name: "Login",
  data() {
    return {
      loading: false,
      data: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    login() {
      setTimeout(() => {
        if (!this.data.username || !this.data.password) {
          this.$toast("Username dan password harus diisi");
          return;
        }

        this.loading = true;
        api
          .postJson("/v1/kidora/SdmController/login", this.data)
          .then((res) => {
            this.loading = false;
            if (res.valid) {
              this.$toast("Login berhasil");
              res.data.is_login = 1;
              this.$store.commit("login/setData", res.data);
              this.$router.go("-1");
            } else {
              this.$toast("Login gagal");
            }
          })
          .catch((e) => {
            this.$toast("Server bermasalah");
          });
      }, 150);
    },
  },
};
</script>

<style lang="scss" scoped="true">
.page {
  position: relative;
  height: 100vh;
  overflow: hidden;
}
.circle {
  background-color: #e5f3fb;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  position: absolute;
  z-index: -1;
  &.top {
    top: -200px;
    left: -200px;
  }
  &.bottom {
    bottom: -200px;
    right: -200px;
  }
}
.login {
  margin: 100px 20px;
  form {
    margin-top: 60px;
  }
}
</style>
