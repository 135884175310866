import axios from 'axios';

const baseUrl = 'https://api.pspp-integrated.com/api';

export const get = (url) => {
    return axios.get(baseUrl + url)
        .then(response => {
            return response.data;
        });
}

export const post = (url, data, config) => {
    return axios.post(baseUrl + url, data, config)
        .then(response => {
            return response.data;
        });
}

export const postJson = (url, data) => {
    const json = JSON.stringify(data);
    return axios.post(baseUrl + url, json, {
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(response => {
        return response.data;
    });
}