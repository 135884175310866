<template>
  <div class="page">
    <div class="top">
      <h3>{{ hariTanggal }}</h3>
      <div class="checkpoint" v-if="rules.id_checkpoint">
        <Location />
        <div>
          {{ rules.checkpoint }}
          <div
            class="range"
            :class="{ invalid: !location.valid }"
          >
            {{
              location.valid
                ? "Valid radius"
                : "Di luar radius (" + distanceText + ")"
            }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="loading" style="padding-top: 125px">
      <div class="loading" style="margin-top: 100px">
        <div class="dot-falling"></div>
      </div>
      <p style="text-align: center">{{ loadingText }}</p>
    </div>
    <div class="attendance" style="padding-top: 125px" v-else>
      <div class="card">
        <div class="card-body">
          <p>
            Absen Masuk
            <small>Jam masuk {{ rules.jam_masuk }}</small>
          </p>

          <div v-if="clockedIn.status">
            <div class="img-wrapper" v-show="imgSrc">
              <img
                :src="imgSrc"
                height="120"
                id="img-clockin"
                :class="imgClass"
              />
            </div>
            <h1
              id="jam-clockin-selected"
              v-if="clockedIn.status"
              style="margin: 0"
            >
              {{ clockedIn.clock_in }}
            </h1>
          </div>

          <div v-else>
            <h1 id="jam-clockin" v-if="!clockedIn.status">
              {{ hour }}:{{ minute }}:{{ second }}
            </h1>
            <button
              type="button"
              class="btn btn-primary"
              @click="clockIn"
              v-if="!clockedIn.status"
              :disabled="clockedIn.loading"
            >
              Clock In
            </button>
          </div>
        </div>
      </div>

      <div class="box" v-if="!clockedIn.status">
        <p>Jam pulang {{ rules.jam_pulang }}</p>
      </div>

      <div class="card" :class="{ disabled: !clockedIn.status }" v-else>
        <div class="card-body">
          <p>
            Absen Pulang
            <small>Jam pulang {{ rules.jam_pulang }}</small>
          </p>

          <div v-if="clockedOut.status">
            <div class="img-wrapper" v-show="imgSrcOut">
              <img
                :src="imgSrcOut"
                height="120"
                id="img-clockout"
                :class="imgClassOut"
              />
            </div>
            <h1
              id="jam-clockout-selected"
              v-if="clockedOut.status"
              style="margin: 0"
            >
              {{ clockedOut.clock_out }}
            </h1>
          </div>

          <div v-else>
            <h1 id="jam-clockout" v-if="!clockedOut.status">
              {{ hour }}:{{ minute }}:{{ second }}
            </h1>
            <button
              type="button"
              class="btn btn-primary"
              @click="clockOut"
              v-if="!clockedOut.status"
              :disabled="clockedOut.loading"
            >
              Clock Out
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" v-show="modalOpen" @click="closeModal">
      <div class="modal-body">
        <div class="camera">
          <video id="video" v-show="!showPhoto">
            Video stream not available.
          </video>
          <img id="photo" v-show="showPhoto" />
          <div class="camera-controller" v-show="!showPhoto">
            <button
              type="button"
              class="btn-photo"
              @click="takePicture"
            ></button>
          </div>
          <div class="camera-controller ok" v-show="showPhoto">
            <button @click="openCamera">Ulangi</button>
            <button @click="absen">
              {{ loadingAbsen ? "Loading..." : "OK" }}
            </button>
          </div>
        </div>

        <canvas id="canvas"></canvas>
      </div>
    </div>

    <div class="modal" v-show="modalPentingOpen" ref="modal">
      <div
        class="modal-body"
        :class="{ open: modalPentingBodyOpen }"
        style="height: 220px"
      >
        <p><b>PENTING</b></p>
        <p>Untuk mengoptimalkan penggunaan aplikasi, mohon ijinkan akses ke:</p>
        <ul>
          <li>GeoLocation</li>
          <li>Camera</li>
        </ul>
        <button
          type="button"
          class="btn btn-primary"
          @click="closeModalPenting()"
        >
          Tutup
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import * as api from "./../api";

import Check from "../assets/icons/Check.vue";
import MapMarker from "../assets/icons/Marker.vue";
import Location from "../assets/icons/Location.vue";

export default {
  name: "Home",
  components: {
    Check,
    MapMarker,
    Location,
  },
  data() {
    return {
      loading: true,
      loadingText: "Mencari lokasi",
      loadingAbsen: false,

      user: this.$store.state.login.data,
      rules: {},
      adaPekerjaan: false,

      hariTanggal: "",
      hour: "",
      minute: "",
      second: "",
      date: "",
      now: "",

      clockType: "",

      destinationType: "",
      pictureSource: "",
      isDeviceReady: false,
      imgSrc: "",
      imgSrcOut: "",
      imgClass: "",
      imgClassOut: "",

      location: {
        status: false,
        lat: 0.0,
        lng: 0.0,
      },
      distanceText: "",

      clockedIn: {
        status: false,
        clock_in: "",
        foto_in: "",
        lat_in: null,
        lng_in: null,
        loading: false,
      },

      clockedOut: {
        status: false,
        clock_out: "",
        foto_out: "",
        lat_out: null,
        lng_out: null,
        loading: false,
      },

      modalOpen: false,
      showPhoto: false,
      video: null,
      canvas: null,
      photo: null,
      localStream: null,
      streaming: false,
      dataImage: null,

      modalPentingOpen: false,
      modalPentingBodyOpen: false,

      width: 500,
      height: 0,
    };
  },
  methods: {
    moment,
    closeModalPenting() {
      setTimeout(() => {
        localStorage.setItem("info_akses", 1);

        this.modalPentingOpen = false;
        this.modalPentingBodyOpen = false;

        setTimeout(() => {
          this.$refs["modal"].remove();
        }, 100);
      }, 150);
    },
    jam() {
      const date = new Date(this.now);
      this.hour = moment(date).format("HH");
      this.minute = moment(date).format("mm");
      this.second = moment(date).format("ss");

      setTimeout(() => {
        this.now += 1000;
        this.jam();
      }, 1000);
    },
    openCamera() {
      setTimeout(() => {
        this.showPhoto = false;
        this.modalOpen = true;

        this.video = document.getElementById("video");
        this.canvas = document.getElementById("canvas");
        this.photo = document.getElementById("photo");

        navigator.mediaDevices
          .getUserMedia({ video: true, audio: false })
          .then((stream) => {
            this.localStream = stream;
            this.video.srcObject = stream;
            this.video.play();
          })
          .catch(function (err) {
            this.$toast("Tidak dapat membuka Kamera");
          });

        this.video.addEventListener(
          "canplay",
          (ev) => {
            if (!this.streaming) {
              this.height =
                this.video.videoHeight / (this.video.videoWidth / this.width);

              if (isNaN(this.height)) {
                this.height = this.width / (4 / 3);
              }

              this.video.setAttribute("width", this.width);
              this.video.setAttribute("height", this.height);
              this.canvas.setAttribute("width", this.width);
              this.canvas.setAttribute("height", this.height);

              this.streaming = true;
            }
          },
          false
        );
      }, 150);
    },
    clockIn() {
      this.openCamera();
      this.clockType = "CLOCK_IN";
    },
    clockOut() {
      this.openCamera();
      this.clockType = "CLOCK_OUT";
    },
    absen() {
      this.loadingAbsen = true;
      if (this.clockType == "CLOCK_OUT") {
        this.imgSrcOut = this.dataImage;

        this.clockedOut.id_user = this.$store.state.login.data.id;
        this.clockedOut.clock_out =
          document.querySelector("#jam-clockout").textContent;
        this.clockedOut.lat_out = this.location.lat;
        this.clockedOut.lng_out = this.location.lng;
        this.clockedOut.distance_out = this.location.distance;
        this.clockedOut.foto_out = this.imgSrcOut;
        this.clockedOut.loading = true;

        api
          .post("/v1/kidora/KehadiranController/clock_out", this.clockedOut)
          .then((r) => {
            this.$toast("Clock Out Berhasil");
            this.clockedOut.loading = false;
            this.clockedOut.status = r.valid;

            this.modalOpen = false;
            this.showPhoto = false;
            this.turnOffCamera();

            this.loadingAbsen = false;
          })
          .catch((e) => {
            this.loadingAbsen = false;

            this.clockedOut.loading = false;
            this.$toast("Clock Out Gagal");
          });
      } else {
        this.imgSrc = this.dataImage;

        this.clockedIn.id_user = this.$store.state.login.data.id;
        this.clockedIn.clock_in =
          document.querySelector("#jam-clockin").textContent;
        this.clockedIn.lat_in = this.location.lat;
        this.clockedIn.lng_in = this.location.lng;
        this.clockedIn.distance_in = this.location.distance;
        this.clockedIn.foto_in = this.imgSrc;
        this.clockedIn.loading = true;

        api
          .post("/v1/kidora/KehadiranController/clock_in", this.clockedIn)
          .then((r) => {
            this.$toast("Clock In Berhasil");
            this.clockedIn.loading = false;
            this.clockedIn.status = r.valid;

            this.modalOpen = false;
            this.showPhoto = false;
            this.turnOffCamera();

            this.loadingAbsen = false;
          })
          .catch((e) => {
            this.loadingAbsen = false;

            this.$toast("Clock In Gagal");
            this.clockedIn.loading = false;
          });
      }
    },
    onLocationSuccess(position) {
      this.location = {
        status: true,
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };

      this.getRules();
      this.getData();
    },
    onLocationFailed() {
      this.loading = false;
      this.$toast("Gagal mengambil lokasi");

      this.getRules();
      this.getData();
    },
    calculateDistance(lat1, lon1, lat2, lon2, unit) {
      if (lat1 == lat2 && lon1 == lon2) {
        return 0;
      } else {
        var radlat1 = (Math.PI * lat1) / 180;
        var radlat2 = (Math.PI * lat2) / 180;
        var theta = lon1 - lon2;
        var radtheta = (Math.PI * theta) / 180;
        var dist =
          Math.sin(radlat1) * Math.sin(radlat2) +
          Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
          dist = 1;
        }
        dist = Math.acos(dist);
        dist = (dist * 180) / Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit == "meter") {
          dist = dist * 1.609344 * 1000;
        }
        if (unit == "K") {
          dist = dist * 1.609344;
        }
        if (unit == "N") {
          dist = dist * 0.8684;
        }
        return dist;
      }
    },
    getData() {
      this.loadingText = "Mengambil data";
      const id_user = this.$store.state.login.data.id;
      api
        .get("/v1/kidora/KehadiranController/get?id_user=" + id_user)
        .then((r) => {
          this.loading = false;

          if (r.data.kehadiran.clock_in) {
            this.clockedIn = {
              status: true,
              clock_in: moment(r.data.kehadiran.clock_in).format("HH:mm:ss"),
            };
            this.imgSrc = r.data.kehadiran.thumbnail_in;
          }
          if (r.data.kehadiran.clock_out) {
            this.clockedOut = {
              status: true,
              clock_out: moment(r.data.kehadiran.clock_out).format("HH:mm:ss"),
            };
            this.imgSrcOut = r.data.kehadiran.thumbnail_out;
          }
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    getRules() {
      api
        .get("/v2/kidora/SdmController/getRules?id=" + this.user.id)
        .then((r) => {
          if (r.valid) {
            this.rules = r.data;

            this.now = r.data.jam;
            this.jam();

            if (this.rules.id_checkpoint) {
              this.location.distance = this.calculateDistance(
                this.location.lat,
                this.location.lng,
                this.rules.lat,
                this.rules.lng,
                "meter"
              );

              if (this.location.distance > 1000) {
                this.distanceText =
                  (this.location.distance / 1000).toFixed(2) + " km";
              } else {
                this.distanceText = this.location.distance + " m";
              }

              if (this.location.distance <= this.rules.radius) {
                this.location.valid = true;
              } else {
                this.location.valid = false;
              }
            }            
          }
        })
        .catch((e) => {
          this.$toast("Gagal mengambil data");
        });
    },
    takePicture() {
      setTimeout(() => {
        var context = this.canvas.getContext("2d");
        this.canvas.width = this.width;
        this.canvas.height = this.height;
        context.drawImage(this.video, 0, 0, this.width, this.height);

        this.dataImage = this.canvas.toDataURL("image/jpeg");
        this.photo.setAttribute("src", this.dataImage);
        this.showPhoto = true;

        setTimeout(() => {
          this.turnOffCamera();
        }, 150);
      }, 150);
    },
    clearphoto() {
      var context = this.canvas.getContext("2d");
      context.fillStyle = "#AAA";
      context.fillRect(0, 0, this.canvas.width, this.canvas.height);

      var data = this.canvas.toDataURL("image/png");
      this.photo.setAttribute("src", data);
    },
    closeModal(e) {
      if (e.target.classList[0] == "modal") {
        this.modalOpen = false;
        this.showPhoto = false;
        this.turnOffCamera();
      }
    },
    turnOffCamera() {
      this.video.pause();
      this.video.src = "";
      this.localStream.getTracks()[0].stop();
    },
  },
  mounted() {
    setTimeout(() => {
      if (!localStorage.getItem("info_akses")) {
        this.modalPentingOpen = true;
        setTimeout(() => {
          this.modalPentingBodyOpen = true;
        }, 100);
      } else {
        this.$refs["modal"].remove();
      }
    }, 100);

    if (navigator.geolocation) {
      this.loadingText = "Mencari lokasi";
      navigator.geolocation.getCurrentPosition(
        this.onLocationSuccess,
        this.onLocationFailed
      );
    } else {
      this.getRules();
      this.getData();
      this.$toast("Perangkat tidak mendukung lokasi");
    }

    const hari = moment().format("ddd");
    const tgl = moment().format("DD");
    const bln = parseInt(moment().format("MM"));
    const thn = moment().format("YYYY");

    const bulan = [
      "",
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];
    const namaHari = {
      Sun: "Minggu",
      Mon: "Senin",
      Tue: "Selasa",
      Wed: "Rabu",
      Thu: "Kamis",
      Fri: "Jumat",
      Sat: "Sabtu",
    };

    this.hariTanggal = `${namaHari[hari]}, ${tgl} ${bulan[bln]} ${thn}`;
  },
};
</script>

<style lang="scss" scoped="true">
.page {
  background: var(--light-blue);
  min-height: 100vh;
}
.top {
  padding: 20px;
  background: #fff;
  box-shadow: 0px 3px 3px #ccc;
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100% - 40px);

  h3 {
    margin: 0;
  }

  .checkpoint {
    display: flex;
    margin-top: 10px;
    svg {
      height: 20px;
      margin-right: 4px;
    }
  }

  .range {
    display: flex;
    align-items: center;
    margin-top: 5px;
    color: #777;
    font-size: 14px;

    &.invalid {
      color: #f00;
    }
    svg {
      height: 20px;
      margin-right: 5px;
    }
  }
}
.profil {
  margin-bottom: 0;
  small {
    color: #777;
  }
}
.card {
  margin: 20px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0px 3px 3px #ccc;
  &.disabled {
    background: #f7f7f7;
    color: #999;
  }
  .card-body {
    padding: 15px;
    p {
      margin: 0;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: center;
      small {
        font-weight: normal;
      }
    }
    h1 {
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        height: 30px;
        margin-left: 5px;
      }
    }
    .clocked {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        margin-left: 15px;
      }
    }
  }
}
.box {
  margin: 20px;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 6px;
  p {
    margin: 0;
    color: #999;
  }
}
.img-wrapper {
  text-align: center;
  margin-top: 30px;
  height: 170px;
  width: 150px;
  margin: 20px auto;
  img {
    border-radius: 6px;
    box-shadow: -3px 3px 3px #999;
    transition: all 0.5s ease;
    width: 100%;
    height: 100%;
    object-fit: cover;
    &.rotate {
      transform: rotate(-90deg);
    }
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  display: flex;
  justify-content: center;
  .modal-body {
    border-radius: 5px;
    margin: 30px;
    background: #fff;
    padding: 15px;
    margin-top: 20px;
    height: 400px;
    width: 300px;
  }
}

.camera {
  position: relative;
  #video,
  #photo {
    width: 100%;
    height: 400px;
    object-fit: contain;
  }
  .camera-controller {
    position: absolute;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 70px;
    margin-top: -6px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.ok {
      height: 55px;
      justify-content: space-between;
      button {
        background-color: #fff;
        border: 0;
        outline: none;
        padding: 10px 15px;
        border-radius: 4px;
        text-transform: uppercase;
        transition: all 0.3s ease;
        width: 80px;
        &:active {
          transform: scale(0.9);
        }
        &:first-child {
          margin-left: 10px;
        }
        &:last-child {
          margin-right: 10px;
        }
      }
    }
  }
  .btn-photo {
    width: 55px;
    height: 55px;
    border-radius: 70px;
    border: 0;
    background-color: #fff;
    transition: all 0.3s ease;
    &:active {
      width: 50px;
      height: 50px;
    }
  }
}

#canvas {
  display: none;
}
</style>
