<template>
  <div class="page">
    <h3>Profil</h3>

    <form>
      <div class="form-group">
        <label>Nama</label>
        <input type="text" v-model="data.nama" readonly>
      </div>
      <div class="form-group">
        <label>Email</label>
        <input type="email" v-model="data.email" readonly>
      </div>
      <div class="form-group">
        <label>No HP</label>
        <input type="tel" v-model="data.no_hp" readonly>
      </div>
    </form>

    <button type="button" class="btn btn-primary" @click="logout">Logout</button>
  </div>
</template>

<script>
import * as api from "../api";

export default {
  name: "Home",
  data() {
    return {
      user: this.$store.state.login.data,
      data: [],
    };
  },
  methods: {
    load() {
      api.get("/v1/kidora/SdmController/get?id=" + this.user.id).then((r) => {
        this.data = r.data;
      });
    },
    logout() {
      localStorage.clear();
      location.reload();
    },
  },
  mounted() {
    this.load();
  },
};
</script>

<style lang="scss" scoped="true">
.page {
  padding: 20px;
  h3 {
    margin: 0;
  }
  form {
    margin-top: 30px;
  }
  .btn {
    position: absolute;
    width: calc(100% - 40px);
    bottom: 80px;
  }
}
</style>
