import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login'
import Home from '../views/Home'
import Pekerjaan from '../views/Pekerjaan'
import Kehadiran from '../views/Kehadiran'
import Profil from '../views/Profil'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/pekerjaan/:id',
    name: 'Pekerjaan',
    component: Pekerjaan
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/kehadiran',
    name: 'Kehadiran',
    component: Kehadiran
  },
  {
    path: '/profil',
    name: 'Profil',
    component: Profil
  },
]

const router = new VueRouter({
  routes
})

export default router
