const state = {
    data: {}
}

const mutations = {
    setData(state, data) {
        state.data = data
    },
    updatePassword(state,  data) {
        state.data.password = data.password;
        state.data.last_password_updated = data.last_password_updated;
    }
}

export default {
    namespaced: true,
    state,
    mutations,
}
