import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";

Vue.prototype.$toast = (text) => {
  Toastify({
    text: text,
    gravity: "bottom",
    position: "center",
    style: {
      background: "#333",
      fontFamily: "Avenir",
      borderRadius: "6px",
      fontSize: "14px"
    },
    offset: {
      y: 60
    }
  }).showToast();
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
